var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dataLoading,
          expression: "dataLoading",
        },
      ],
      staticClass: "div-main-list-page",
      staticStyle: { overflow: "auto" },
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "div-main-list-page-table",
          staticStyle: { "margin-top": "10px" },
        },
        [
          _c(
            "el-tabs",
            {
              on: {
                "tab-click": function ($event) {
                  return _vm.getLogList(_vm.pagination.pageSize, 0)
                },
              },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: _vm.TRANSFER_TYPE.SHIP,
                  name: _vm.TRANSFER_TYPE.SHIP,
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: _vm.TRANSFER_TYPE.CO,
                  name: _vm.TRANSFER_TYPE.CO,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { height: "calc(100% - 50px)" } },
            [
              _c("div", { staticClass: "filter-line" }),
              _c(
                "el-table",
                {
                  ref: "transferLogTable",
                  attrs: {
                    data: _vm.tableDate,
                    height: "100%",
                    width: "100%",
                    border: true,
                    "header-cell-style": _vm.HEADER_CELL_STYLE,
                    "cell-style": _vm.CELL_STYLE,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Name", "min-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.name
                                      ? scope.row.name.toUpperCase()
                                      : "-"
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.activeName == _vm.TRANSFER_TYPE.SHIP
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "imo",
                          label: "IMO",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.imo
                                            ? scope.row.imo.toUpperCase()
                                            : "-"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1142627719
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "action",
                      label: "Action",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.action ? scope.row.action : "-"
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "240px",
                      label: "Transferred To/From",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.transferToOrFrom
                              ? _c("div", { staticClass: "transfer-to-from" }, [
                                  scope.row.action ==
                                  _vm.TRANSFER_STATUS.TRANSFER
                                    ? _c(
                                        "span",
                                        { staticClass: "arrow-icon" },
                                        [
                                          _vm.activeName ==
                                          _vm.TRANSFER_TYPE.SHIP
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/action_icons/transfer_to.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/action_icons/transfer_co_to.png"),
                                                  alt: "",
                                                },
                                              }),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "arrow-icon" },
                                        [
                                          _vm.activeName ==
                                          _vm.TRANSFER_TYPE.SHIP
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/action_icons/transfer_from.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/action_icons/transfer_co_from.png"),
                                                  alt: "",
                                                },
                                              }),
                                        ]
                                      ),
                                  _c(
                                    "p",
                                    {
                                      style: {
                                        color:
                                          scope.row.action ==
                                          _vm.TRANSFER_STATUS.TRANSFER
                                            ? "#222BFF"
                                            : "#FC7C05",
                                        fontWeight: "400",
                                        display: "block",
                                        fontSize: "14px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.transferToOrFrom
                                            ? scope.row.transferToOrFrom
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updatedAt",
                      label: "Date and Time",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.updatedAt
                                      ? _vm
                                          .$moment(scope.row.updatedAt)
                                          .format("YYYY-MM-DD HH:mm:ss")
                                      : "-"
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.pagination.pageSize,
                      "page-sizes": [5, 10, 20, 50, 100],
                      "current-page": _vm.pagination.pageNumber,
                      layout: "total, sizes, prev, pager, next",
                      total: _vm.pagination.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.pagination, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.pagination, "pageSize", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div-main-list-page-title" }, [
      _c("h1", [_vm._v("Ship Transfer Log")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
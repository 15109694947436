<template>
  <div style="overflow: auto;" v-loading="dataLoading" class="div-main-list-page">
    <div class="div-main-list-page-title">
      <h1>Ship Transfer Log</h1>
    </div>
    <div class="div-main-list-page-table" style="margin-top:10px">
        <el-tabs v-model="activeName" @tab-click="getLogList(pagination.pageSize,0)">
          <el-tab-pane :label="TRANSFER_TYPE.SHIP" :name="TRANSFER_TYPE.SHIP"/>
          <el-tab-pane :label="TRANSFER_TYPE.CO" :name="TRANSFER_TYPE.CO"/>
        </el-tabs>
        <div style="height: calc(100% - 50px);" >
          <div class="filter-line" >
            <!-- <div style="margin-right:10px">
              <el-select
                v-model="filter.ship"
                @change="getLogList(pagination.pageSize,0)"
              >
                <el-option
                  v-for="item in nameFilterOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <!-- <div style="margin-right:10px">
            <el-select
              v-model="filter.action"
              @change="getLogList(pagination.pageSize,0)"
            >
              <el-option
                v-for="item in actionFilterOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </div> -->
            <!-- <div style="margin-right:10px">
              <el-select
                style="width:100%"
                v-model="filter.organizationId"
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword to search company"
                :remote-method="getTransferToCompanyOpts"
                :loading="selectOpstSearchLoading"
                @change="getLogList(pagination.pageSize,0)"
                >
                <el-option
                  v-for="item in transferToCompanyOpst"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >
                </el-option>
              </el-select>
            </div> -->
            <!-- <div>
              <el-date-picker
                v-model="filter.dateRange"
                type="datetimerange"
                range-separator="to"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                @change="getLogList(pagination.pageSize,0)"
              >
              </el-date-picker>
            </div> -->
            <!-- <div style="margin: auto 0">
              <span
                style="width:50px;"
                class="span-btn-primay"
                @click="handleResetSearchQuery()"
              >
                Reset
              </span>
            </div> -->
          </div>
          <el-table
            ref="transferLogTable"
            :data="tableDate"
            height="100%"
            width="100%"
            :border="true"
            :header-cell-style="HEADER_CELL_STYLE"
            :cell-style="CELL_STYLE"
            >
            <el-table-column
              prop="name"
              label="Name"
              min-width="200">
              <template v-slot="scope">
                <span> {{scope.row.name ? scope.row.name.toUpperCase() : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeName == TRANSFER_TYPE.SHIP"
              prop="imo"
              label="IMO"
              min-width="100">
              <template v-slot="scope">
                <span> {{scope.row.imo ? scope.row.imo.toUpperCase() : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Action"
              min-width="120"
              >
              <template v-slot="scope">
                <span> {{scope.row.action ? scope.row.action : '-' }}</span>
              </template>
            </el-table-column>
             <el-table-column min-width="240px"  label="Transferred To/From" show-overflow-tooltip>
            <template v-slot="scope">
              <div v-if="scope.row.transferToOrFrom"  class="transfer-to-from">
                <span  v-if="scope.row.action == TRANSFER_STATUS.TRANSFER"  class="arrow-icon">
                   <img  v-if="activeName == TRANSFER_TYPE.SHIP" src="@/assets/images/action_icons/transfer_to.png" alt="">
                  <img  v-else src="@/assets/images/action_icons/transfer_co_to.png" alt="">
                </span>
                <span  v-else class="arrow-icon">
                  <img v-if="activeName == TRANSFER_TYPE.SHIP" src="@/assets/images/action_icons/transfer_from.png" alt="">
                  <img v-else src="@/assets/images/action_icons/transfer_co_from.png" alt="">
                </span>
                <p :style="{color: scope.row.action == TRANSFER_STATUS.TRANSFER ? '#222BFF': '#FC7C05',fontWeight:'400',display:'block',fontSize:'14px'}" >{{scope.row.transferToOrFrom ? scope.row.transferToOrFrom : ''}}</p>
              </div>
            </template>
          </el-table-column>
            <el-table-column
              prop="updatedAt"
              label="Date and Time"
              min-width="120">
              <template v-slot="scope">
                <span> {{scope.row.updatedAt ? $moment(scope.row.updatedAt).format("YYYY-MM-DD HH:mm:ss") : '-'}}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size.sync="pagination.pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            :current-page="pagination.pageNumber"
            layout="total, sizes, prev, pager, next"
            :total="pagination.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { sortByAlphabetical, openBlobUrlInNewTab, toSgUtcDate, serverSGDateTimeFormat } from '@/utils'
import { TRANSFER_TYPE, HEADER_CELL_STYLE, CELL_STYLE, TRANSFER_STATUS } from '@/constants.js'
export default {
  name: 'ShipTransferLog',
  data () {
    const filter = {
      dateRange: [],
      action: '',
      company: '',
      name: ''
    }
    return {
      TRANSFER_TYPE,
      HEADER_CELL_STYLE,
      TRANSFER_STATUS,
      CELL_STYLE,
      activeName: TRANSFER_TYPE.SHIP,
      filter,
      dataLoading: false,
      companies: [],
      tableDate: [],
      coTransferLogs: [],
      companyId: null,
      companySearchKey: null,
      timezoneOffset: 480,
      pagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0
      },
      selectOpstSearchLoading: false,
      transferToCompanyOpst: [],
      transferToCompanyList: []
    }
  },
  computed: {
    ...mapState(['currentCompany', 'permissions']),

    shipFilterOpts () {
      return [{ value: null, label: 'All Ship' }]
    },
    companyFilterOpts () {
      const list = this.companies.filter(
        (c) =>
          c.id !== this.selectedCompany.id
      )
      return [{ value: null, label: 'All Company' }].concat(list)
    },
    actionFilterOpts () {
      return [
        { value: null, label: 'All Action Type' },
        { value: 'STOP_TRANSFER', label: 'TERMINATE' },
        { value: 'TRANSFER', label: 'TRANSFER' }
      ]
    },
    nameFilterOpts () {
      return [{ value: null, label: 'All Name' }
      ]
    }

  },
  mounted () {
    this.filter.action = this.actionFilterOpts[0].value
    this.filter.name = this.nameFilterOpts[0].value
    this.filter.organizationId = null
    this.getTransferToCompanyOpts()
    this.getLogList(this.pagination.pageSize, 0)
  },
  methods: {
    sortByAlphabetical,
    serverSGDateTimeFormat,
    openBlobUrlInNewTab,
    toSgUtcDate,

    async getTransferToCompanyOpts (searchKey, isGetAll) {
      this.selectOpstSearchLoading = true
      const hasSearchKey = searchKey !== undefined && searchKey.trim() !== ''
      const pageSize = hasSearchKey || isGetAll ? '999' : '10'
      let url = `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=${pageSize}&offline=false`
      if (hasSearchKey) {
        url += `&companyName=${searchKey}`
      }
      try {
        const res = await this.$request.get({
          url
        })
        if (res?.code === 1000 && res.data?.records?.length > 0) {
          this.transferToCompanyOpst = res.data.records.filter(item => item.id !== this.currentCompany.id)?.map(item => {
            return {
              label: item.companyName,
              value: item.id
            }
          })
          if (isGetAll) {
            this.transferToCompanyList = this.transferToCompanyOpst
          }
        } else {
          this.transferTocompanyOpst = []
        }
      } catch (e) {
        this.$message.error('Error retrieving list of companies')
      } finally {
        this.selectOpstSearchLoading = false
      }
    },
    async getLogList (pageSize, pageNumber) {
      this.dataLoading = true
      const filter = ''
      // filter = `${this.filter.action !== this.actionFilterOpts[0] ? `type:${this.filter.action}` : ''}`
      // filter += `${this.filter.name !== this.nameFilterOpts[0] ? `${filter ? 'and' : '&filter='} name:'${this.filter.name}'` : ''}`
      // filter += `${this.filter.organizationId !== null ? `${filter ? 'and' : '&filter='}tenantId:${this.filter.organizationId}` : ''}`
      // filter += `${this.filter.dateRange.length > 0 ? `${filter ? 'and' : '&filter='} startDate>:'${this.filter.dateRange[0].toISOString()}' and endDate<:'${this.filter.dateRange[1].toISOString()}'` : ''}`

      const params = `?pageSize=${pageSize}&pageNumber=${pageNumber}${filter ?? ''}`// &sort=updatedAt DESC
      let url = ''
      if (this.activeName === this.TRANSFER_TYPE.SHIP) {
        url = `${this.$apis.shipTransferLogs}${params}`
      } else {
        url = `${this.$apis.cargoOfficerTransferLogs}${params}`
      }
      const isShipLog = this.activeName === this.TRANSFER_TYPE.SHIP
      try {
        const res = await this.$request.get({ url })
        if (res?.code === 1000 && res.data?.content?.length > 0) {
          this.pagination.total = res.data.totalElements
          if (res.data.content != null && res.data.content.length > 0) {
            try {
              await this.getTransferToCompanyOpts('', true)
            } catch (error) {

            }
            this.tableDate = res.data.content.map(log => {
              const isOwner = this.currentCompany.id === (isShipLog ? log.tenantId : log.organizationId)
              const transferOrgId = isOwner ? log.charterId : (isShipLog ? log.tenantId : log.organizationId)
              return {
                updatedAt: log.updatedAt,
                isOwner: isOwner,
                transferToOrFrom: this.transferToCompanyList?.find(item => item.value === transferOrgId)?.label ?? '',
                action: this.TRANSFER_STATUS[log.type],
                name: isShipLog ? log.shipName : log.cargoOfficerName,
                imo: log.imo

              }
            })
          }
        } else {
          this.tableDate = []
        }
      } catch (e) {
        this.$message.error('Error retrieving list of transfer history')
      } finally {
        this.dataLoading = false
      }
    },
    handleResetSearchQuery () {
      Object.assign(this.$data, this.$options.data())
    },
    handleCurrentChange (pageNumber) {
      if (this.pagination.pageNumber !== pageNumber) {
        this.pagination.pageNumber = pageNumber
        this.getLogList(this.pagination.pageSize, this.pagination.pageNumber - 1)
      }
    },
    handleSizeChange (pageSize) {
      this.getLogList(pageSize, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-line{
  padding-top:8px;
  padding-bottom:8px;
  display:flex;
  flex-direction: row;
  justify-content: start;

}
.subtile{
  width:500px;
  height: 20px;
  display:flex;
  flex-direction: row;
  justify-content: start;
  font-size: 14.5;
  font-weight: 500;
}

.pagination-container {
  text-align: right;
  margin-top:4px;
}
/deep/.el-table__column-filter-trigger  {
  i{
    display: inline-block;
    padding-left:4px;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

}
.transfer-to-from {
    display: flex;
    justify-items: center;
    justify-content: left;
  .arrow-icon{
    height: 100%;
    display: block;
    margin: auto 0;
    padding-top: 4px;
  }
}

</style>
